/* Import Page Styles */
.import-page {
  @apply max-w-3xl mx-auto text-center p-5 rounded-lg;
  @apply text-gray-800; /* Light mode */
}

/* Dark Mode */
.dark-mode .import-page {
  @apply text-gray-200;
}

.import-title {
  @apply text-2xl font-bold mb-2 text-blue-800;
}

/* Dark Mode */
.dark-mode .import-title {
  @apply text-gray-200;
}

.import-description {
  @apply text-lg text-center text-gray-600 mb-5;
}

@media (max-width: 600px){
  .import-description{
    @apply text-justify;
  }
}

/* Dark Mode */
.dark-mode .import-description {
  @apply text-gray-400;
}

.file-upload-container {
  @apply relative inline-block mb-5;
}

.file-input-label {
  @apply flex text-2xl items-center justify-center bg-red-600 text-white py-3 px-6 rounded-lg cursor-pointer text-lg font-bold transition-colors;
}

/* Dark Mode */
.dark-mode .file-input-label {
  @apply bg-red-700;
}

.upload-icon {
  @apply text-2xl mr-2;
}

.file-input {
  @apply hidden;
}

.import-hint {
  @apply text-sm text-gray-600 mt-2;
}

/* Dark Mode */
.dark-mode .import-hint {
  @apply text-gray-400;
}

.upload-status {
  @apply mt-5 p-3 rounded-md text-center;
}

/* Success status */
.upload-status.success {
  @apply text-green-600 border border-green-600 bg-green-100;
}

/* Error status */
.upload-status.error {
  @apply text-red-600 border border-red-600 bg-red-100;
}

/* Dark Mode Success */
.dark-mode .upload-status.success {
  @apply text-green-300 border-green-300 bg-green-900;
}

/* Dark Mode Error */
.dark-mode .upload-status.error {
  @apply text-red-300 border-red-300 bg-red-900;
}

.file-confirmation {
  @apply my-4;
}

.dark-mode .file-name {
  @apply text-gray-400;
}

.file-name {
  @apply font-bold text-gray-700 mb-2;
}

.button-group {
  @apply flex justify-center gap-4;
}

.upload-button,
.import-cancel-button {
  @apply !bg-white text-black py-2 px-4 rounded-lg hover:!bg-yellow-50 transition-colors font-semibold border-2 border-black;
  @apply w-32; /* Set equal width for both buttons */
}

/* Dark Mode */
.dark-mode .upload-button,
.dark-mode .import-cancel-button {
  @apply border-white;
}

.file-image-container {
  @apply mb-4 flex justify-center;
}

.file-image {
  @apply h-96 w-80 object-contain bg-cover rounded-3xl border-2 border-black;
  background-image: url('../../public/lightUpload.png');
}

.dark-mode .file-image{
  @apply border-white;
  background-image: url('../../public/darkUpload.jpeg');
}