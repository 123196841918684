/* Tailwind CSS Imports */
@tailwind base;
@tailwind components;
@tailwind utilities;
/* Navbar Styles */
.navbar {
  @apply flex items-center justify-between bg-white shadow-md fixed w-[calc(100%-70px)] h-[70px] right-2 rounded-lg top-0 m-0 z-40 pt-0;
}

@media (max-width: 600px) {
  .navbar{
    @apply !bg-[#1E54AC] !text-white !w-full !right-0 !rounded-none !h-14;
  }
  .logo1 {
    @apply !right-0 !left-0 !pt-0 !pl-0 !pb-0 !pr-0 !p-0;
  }
  .role-text{
    @apply !text-white;
  }
}

.dark-mode .navbar {
  @apply bg-[#1c2633] shadow-md; /* Dark background and shadow for dark mode */
}

.logo1 {
  @apply flex items-center justify-between fixed left-2 right-2 top-0 pl-2 pr-2 pt-2 pb-1 mt-0 z-40 bg-[#E9F3FD];
}

.dark-mode .logo1 {
  @apply bg-[#141b24];
}

.logo-section {
  @apply flex items-center space-x-2 mt-0 w-16 bg-blue-600 rounded-md p-[2px] mr-4; /* Removed margin-top */
}
.app-logo-section {
  @apply flex items-center ml-4 w-10 bg-blue-600 rounded-md p-1; /* Removed margin-top */
}

.app-logo{
  @apply w-12;
}

.logo {
  @apply w-16 mr-4; /* Ensure the logo is properly sized */
}

.nav-links {
  @apply flex items-center space-x-6 text-sm font-semibold mt-3 pl-8 mb-4; /* Increase space between links */
}

.nav-link {
  @apply !text-gray-500 hover:!text-gray-700;
}

.dark-mode .nav-link {
  @apply !text-slate-300 hover:!text-gray-200; /* Light color for dark mode */
}

.nav-link.active {
  @apply !text-black;
}

.dark-mode .nav-link.active {
  @apply !text-white; /* White color for active link in dark mode */
}

.user-section {
  @apply flex items-center space-x-4 ml-auto mr-5;
}

.theme-btn {
  @apply w-8 h-8 bg-gray-600 rounded-full flex items-center justify-center cursor-pointer;
}

.role-text {
  @apply text-gray-600 font-bold; /* Added font-bold for bold text */
}

.theme-icon {
  @apply text-white;
}

.dropdown {
  @apply relative;
}

.dropdown-btn {
  @apply flex items-center justify-center p-2 bg-gray-200 rounded-md cursor-pointer;
}

.dark-mode .dropdown-btn {
  @apply bg-[#676767]; /* Darker background for dropdown button in dark mode */
}

.dropdown-icon {
  @apply text-gray-600;
}

.dark-mode .dropdown-icon {
  @apply text-gray-200 font-bold; /* Light color for dropdown icon in dark mode */
}

.dark-mode .role-text{
  @apply text-white;
}

.dropdown-content {
  @apply absolute -right-4 top-full mt-1 w-32 rounded-lg z-30 font-medium;
}

.dark-mode .dropdown-content {
  @apply border-white; /* Dark background and border for dropdown content in dark mode */
}

.dropdown-item {
  @apply px-4 py-2 text-gray-700 hover:bg-gray-200 z-30;
}

.dark-mode .dropdown-item {
  @apply text-gray-300 hover:bg-gray-700; /* Light text and dark hover background for dropdown items in dark mode */
}

.dark-mode .signout-btn{
  @apply bg-gray-600;
} 

.dark-mode .signout-icon, .dark-mode .signout-text {
  @apply text-[#ededed];
}

/* .nav-tab{
  @apply font-bold no-underline text-gray-400;
}

.dark-mode .nav-tab{
  @apply text-slate-500;
}

.nav-tab.active-tab{
  @apply text-black;
}

.dark-mode .nav-tab.active-tab{
  @apply text-white;
} */