
.dark-mode .filter-paper{
    @apply bg-zinc-800;
}

.dark-mode .filter-title{
    @apply text-white;
}

.filter-hr-divider{
    @apply border-y border-black my-2 w-full;
}

.dark-mode .filter-hr-divider{
    @apply border-white;
}

.dark-mode .filter-text{
    @apply text-white;
}

.dark-mode .filter-select{
    @apply text-white border border-white;
}

.dark-mode .filter-select-item{
    @apply text-white bg-zinc-700 hover:!bg-zinc-600;
}

.dark-mode .Mui-selected.filter-select-item{
    @apply bg-zinc-600;
}

.dark-mode .filter-select svg{
    @apply text-white;
}

