.team-page {
  @apply p-8 flex flex-col gap-10;
}

.section-heading {
  @apply text-2xl font-bold mb-5 text-center;
}

.team-section {
  @apply flex flex-wrap gap-5 justify-center;
}

.user-card {
  @apply w-64 p-4 bg-white rounded-lg shadow-lg text-center;
}

.dark-mode .user-card {
  @apply bg-[#1c2633];
}

.card-image-container {
  @apply mb-2 w-full h-64 relative overflow-hidden rounded-lg;
}

.card-image {
  @apply w-full h-full object-cover rounded-lg;
}

.card-name {
  @apply text-xl font-bold;
}

.card-education {
  @apply my-1;
}

.card-position {
  @apply font-bold text-gray-500 my-1;
}

.dark-mode .card-position {
  @apply text-gray-300;
}

.card-icons {
  @apply mt-2;
}

.icon-link {
  @apply text-2xl mx-2 text-gray-800 hover:text-blue-600;
}

.dark-mode .icon-link {
  @apply text-white;
}