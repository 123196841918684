/* Import the Poppins font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

body {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  background: #E9F3FD;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.sidebar {
  z-index: 10;
}

.main-content {
  margin-top: 60px; /* Space for the navbar height */
  margin-left: 50px; /* Space for the sidebar width and left margin */
  padding: 20px;
  width: calc(100% - 50px); /* Adjusted to account for sidebar width */
  transition: width 0.3s ease;
  flex: 1; /* Allows the content to expand and push the footer down */
}

@media (max-width: 600px){
  .main-content{
    margin-top: 56px !important;
    margin-bottom: 60px;
    padding: 0px;
    margin-left: 0px;
    width: 100%;
  }
  /* When search bar is focused, adjust main-content and z-index */
  .main-content.focused {
    margin-bottom: 0; /* Remove bottom margin */
    z-index: 2000;    /* Ensure it's above navbar */
  }
}

.footer {
  padding: 20px;
  padding-bottom: 10px;
  text-align: center;
}

.dark-mode {
  background-color: #141b24;
  color: #fff;
}

.dark-mode .main-content {
  background-color: #141b24;
  color: #fff;
}

.dark-mode .footer {
  background-color: #141b24;
  color: #fff;
}

.dark-mode .nav-links li a {
  color: #fff;
}

/* Add specific styles for footer in dark mode */
.dark-mode .footer-link {
  color: #fff;
}

.app-mobile-navbar{
  @apply fixed w-full z-50 bottom-0 flex bg-[#e9f3f8];
}

.dark-mode .app-mobile-navbar{
  @apply bg-[#141b24];
}