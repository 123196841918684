@tailwind base;
@tailwind components;
@tailwind utilities;

.table-container {
  @apply p-4;
}

.table-controls {
  @apply flex justify-between items-center mt-3 py-3 mr-10 -ml-1 fixed top-[70px] z-[5] bg-[#E9F3FD] w-[calc(100%-115px)];
}

.dark-mode .table-controls {
  @apply bg-[#141b24];
}

.loading-container {
  @apply flex justify-center items-center h-full mt-16;
}

.paper-container {
  @apply p-0 border-none outline-none mt-16;
}

.search-bar {
  @apply w-52 px-4 py-2 rounded-full bg-white drop-shadow-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500;
}

.dark-mode .search-bar {
  @apply bg-[#1c2633];
}

.search-icon {
  @apply text-gray-500;
}

@media (max-width: 830px) {
  .search-bar{
    @apply w-32 px-3;
  }  
}

@media (max-width: 700px){
  .table-controls{
    @apply text-sm;
  }
  .table-btn{
    @apply px-1;
  }
}

.controls-right {
  @apply flex space-x-4;
}

.table-btn {
  @apply flex items-center space-x-2 px-4 py-2 bg-[#F2F3F3] text-black rounded-md shadow-md hover:bg-[#d2d3d3] transition ease-in-out duration-150;
}

@media (max-width: 830px) {
  .table-btn{
    @apply px-2;
  }  
}

.dark-mode .table-btn {
  @apply text-[#ededed] bg-[#1c2633] hover:!bg-[#3c2633];
}

.table-btn span, .deletion-button span, .retrieve-button span {
  @apply ml-2;
}

.deletion-button, .retrieve-button {
  @apply flex items-center space-x-2 px-4 py-2 rounded-md shadow-md transition ease-in-out duration-150;
}

.deletion-button{
  @apply bg-red-500 hover:bg-red-600 text-[#ededed] disabled:bg-[#f88080];
}

.retrieve-button{
  @apply bg-green-500 hover:bg-green-600 text-[#ededed] disabled:bg-green-300;
}

/* 
.deletion-button {
  @apply bg-red-600 hover:bg-red-700;
}

.export-button {
  @apply bg-yellow-600 hover:bg-yellow-700;
}

.trash-button {
  @apply bg-purple-600 hover:bg-purple-700;
}

.sorter-button {
  @apply bg-green-600 hover:bg-green-700;
} 

.add-btn {
  @apply bg-blue-600 hover:bg-blue-700;
}

*/

.dark-mode .paper-container{
  @apply !border-white;
}

.dark-mode .table-cell svg{
  @apply text-[#cbd5e1] bg-[#1c2633];
}

.dark-mode .table-cell-actions svg{
  @apply text-[#cbd5e1];
}

.dark-mode .nested-table-cell svg{
  @apply text-[#cbd5e1];
}

.dark-mode .nested-table-cell-actions svg{
  @apply text-[#cbd5e1];
}

.data-table {
  @apply w-full rounded-t-lg border-b !border-collapse;
}

.dark-mode .data-table{
  @apply bg-[#1c2633];
}

.table-head {
  @apply bg-gray-100 !font-bold !h-20;
}

.dark-mode .table-head .table-row {
  @apply !bg-[#1c2633] text-[#ededed] hover:!bg-[#2c2633];
}

.table-row {
  @apply hover:bg-gray-50;
}

.dark-mode .table-row {
  @apply bg-[#19222c] hover:!bg-[#29222c] text-[#ededed];
}

.table-head .table-row .table-cell{
  @apply !border-t-0;
}

.table-head .table-row .table-cell-actions{
  @apply !border-t-0;
}

.table-cell {
  @apply py-2 text-left !border-y !border-black !font-bold;
}

.dark-mode .table-cell {
  @apply !border-white text-[#ededed];
}

.hr-response-cell {
  @apply w-1/12; /* Adjust width to your preference */
}

.col1{
  @apply w-10;
}

.table-cell-actions {
  @apply py-2 text-right !border-y !border-black font-bold;
}

.dark-mode .table-cell-actions {
  @apply !border-white text-[#ededed];
}

.nested-table-container {
  @apply mt-0;
}

.nested-table {
  @apply w-full !border-collapse;
}

.nested-table-head {
  @apply bg-gray-100 font-bold !border-y !border-black;
}

.dark-mode .nested-table-head .nested-table-row{
  @apply !bg-[#1c2633] text-[#ededed] !border-white hover:!bg-[#2c2633];
}

.nested-table-row {
  @apply hover:bg-gray-50 !border-y !border-black !h-14;
}

.dark-mode .nested-table-row {
  @apply bg-[#19222c] hover:!bg-[#29222c] text-[#ededed];
}

.nested-table-cell {
  @apply px-2 py-2 text-left !border !border-black !font-bold w-1/12;
}

.dark-mode .nested-table-cell{
  @apply !border-white text-[#ededed];
}

.nested-table-cell-actions {
  @apply !text-right !border !border-black font-bold !border-r-0;
}

.dark-mode .nested-table-cell-actions{
  @apply !border-white text-[#ededed];
}

.contact-button, .email-button, .linkedin-link {
  @apply font-bold !text-[#0d6efd] no-underline hover:underline;
}

.dark-mode .contact-button svg, .dark-mode .email-button, .dark-mode .linkedin-link {
  @apply font-bold !text-[#0d6efd] no-underline hover:underline;
}

.empty-row {
  @apply h-16;
}

.dark-mode .custom-pagination {
  @apply bg-[#1c2633];
}

.edit-dialog-title {
  @apply flex items-center text-xl font-bold text-gray-700; /* Centered title with icon */
}

.dark-mode .edit-dialog-title{
  @apply text-[#ededed];
}

/* Divider Line */
.edit-dialog-divider {
  @apply border-y-2 border-black my-2; /* Light gray horizontal line */
}

.dark-mode .edit-dialog-divider{
  @apply border-slate-400;
}

/* Dialog Content */
.edit-dialog-content {
  @apply space-y-4; /* Spacing between input fields */
}

/* Label and Input Group */
.edit-label-input-group {
  @apply flex flex-wrap items-center space-y-1; /* Flex column for labels and inputs */
}

/* Labels */
.edit-label {
  @apply font-extrabold text-gray-800 px-0 py-2 w-[150px] mr-2; /* Darker gray label text */
}

.dark-mode .edit-label {
  @apply !text-slate-100;
}

.dark-mode .inside-dialog{
  @apply bg-zinc-800;
}

/* Input Fields */
.edit-input {
  @apply bg-slate-100 rounded-md !placeholder-gray-400 border border-gray-700 px-2 py-1 w-[calc(100%-160px)]; /* White background, rounded corners, gray placeholder, border */
}

.dark-mode .edit-input {
  @apply !bg-zinc-600 !text-[#ededed];
}

/* .branch-options {
  @apply flex space-x-2 mt-2; 
}
.branch-chip {
  @apply bg-gray-200 text-gray-700 px-3 py-1 rounded-full cursor-pointer hover:bg-gray-300; 
} */

/* Dialog Actions */
.edit-dialog-actions {
  @apply !flex !justify-start !space-x-4 !mx-3 ; /* Left-aligned buttons with spacing */
}

/* Buttons */
.edit-ok-button, .edit-cancel-button {
  @apply !rounded-2xl !py-2 !px-6 !text-black !font-bold; /* Rounded buttons with padding */
}

/* OK Button */
.edit-ok-button {
  @apply bg-gradient-to-b from-[#dedede] to-[#a1a1a1]; /* Gradient background for OK button */
}

/* Cancel Button */
.edit-cancel-button {
  @apply bg-gradient-to-b from-[#dedede] to-[#a1a1a1]; /* Light gray background for Cancel button */
}

/* .dark-mode .MuiSvgIcon-root {
  color: #ddd;
} */

.invalid-details-text{
  @apply font-bold text-red-600;
}

.dark-mode .invalid-details-text{
  @apply text-slate-400;
}

.dark-mode .edit-lastcontacted-input input{
  @apply !placeholder-white !text-[#ededed];
}

.dark-mode .edit-lastcontacted-input svg{
  @apply text-[#ededed];
}

.contact-dialog-text{
  @apply flex flex-wrap py-1;
}

.dark-mode .contact-dialog-text{
  @apply text-[#ededed];
}

.contact-dialog-divider {
  @apply border-y-2 border-black my-1; /* Light gray horizontal line */
}

.dark-mode .contact-dialog-divider{
  @apply border-slate-400;
}

.status-select{
  @apply text-center w-60;
}

.dark-mode .status-select{
  @apply text-[#ededed] border border-white;
}

.dark-mode .status-select-item{
  @apply text-[#ededed] bg-zinc-700 hover:!bg-zinc-600;
}

.dark-mode .Mui-selected.status-select-item{
  @apply !bg-zinc-600;
}

.dark-mode .status-select svg{
  @apply text-[#ededed];
}

.autocomplete-group{
  @apply sticky font-bold -top-2 px-3 py-3 z-10 bg-slate-200;
}

.autocomplete-item{
  @apply p-0;
}

.dark-mode .autocomplete-group{
  @apply text-[#ededed] bg-gray-900 hover:bg-gray-950;
}

.dark-mode .autocomplete-item{
  @apply text-[#ededed] bg-gray-700;
}

.dark-mode .autocomplete-input textarea{
  @apply !text-[#ededed];
}

.dark-mode .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPopover-paper.MuiMenu-paper{
  @apply border-2 border-white bg-zinc-700;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPopover-paper.MuiMenu-paper{
  @apply border-2 border-black shadow-none;
}