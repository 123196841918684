
.dark-mode .dialog{
    @apply !bg-zinc-900;
}

.dialog-title{
    @apply !font-bold;
}

.dark-mode .dialog-title{
    @apply text-white;
}

.hr-divider{
    @apply border-y-4 border-black;
}

.dark-mode .hr-divider{
    @apply border-slate-200;
}

.main-message{
    @apply !text-lg !font-bold !text-black;
}

.dark-mode .main-message{
    @apply !text-white;
}

.extra-message{
    @apply !text-[#999999];
}

.link-part{
    @apply !text-[#999999];
}

.dialog-actions{
    @apply !mb-2 !mr-2;
}

.cancel-button{
    @apply !bg-slate-200 !text-black !font-bold !rounded-md !p-2 !mx-2 hover:bg-slate-300;
}

.accept-button{
    @apply !rounded-md !p-2 !mx-2 hover:bg-red-600;
}

.dark-mode .inside-dialog{
    @apply bg-zinc-800;
}
