/* Dialog Box */


  /* .css-1t1j96h-MuiPaper-root-MuiDialog-paper{
    @apply !rounded-3xl !border-4 !border-pink-400;
  }

  .css-tlc64q-MuiPaper-root-MuiDialog-paper {
    @apply !bg-[#f7f7f7] !rounded-3xl !border-4 border-pink-400;
  }

  .css-ka7ti6-MuiAutocomplete-root .MuiOutlinedInput-root{
    @apply !p-0;
  }

  .css-1d3z3hw-MuiOutlinedInput-notchedOutline{
    @apply !border-black;
  } */

  /* .css-zevgu-MuiFormControl-root{
    @apply !flex-row;
  } */

.dark-mode .inside-add-dialog{
  @apply bg-zinc-800;
}

/* Dialog Title */
.add-dialog-title {
  @apply flex items-center text-xl font-bold text-gray-700; /* Centered title with icon */
}

.dark-mode .add-dialog-title{
  @apply text-white;
}

/* Divider Line */
.edit-dialog-divider {
  @apply border-y-2 border-black my-2; /* Light gray horizontal line */
}

.dark-mode .edit-dialog-divider{
  @apply border-slate-100;
}

/* Dialog Content */
.edit-dialog-content {
  @apply !space-y-4; /* Spacing between input fields */
}

/* Label and Input Group */
.edit-label-input-group {
  @apply !flex !flex-wrap !flex-row !items-center !space-y-1; /* Flex column for labels and inputs */
}

/* Labels */
.edit-label {
  @apply !font-semibold !text-gray-800 !px-0 !py-2 !w-[90px]; /* Darker gray label text */
}

.dark-mode .edit-label{
  @apply !text-white;
}

/* Input Fields */
.edit-input {
  @apply !bg-slate-100 !rounded-md !placeholder-gray-400 !border !border-gray-700 !px-2 !py-1 w-[calc(100%-100px)]; /* White background, rounded corners, gray placeholder, border */
}

.dark-mode .edit-input{
  @apply bg-zinc-600 text-white;
}

/* .branch-options {
  @apply flex space-x-2 mt-2; 
}
.branch-chip {
  @apply bg-gray-200 text-gray-700 px-3 py-1 rounded-full cursor-pointer hover:bg-gray-300; 
} */

/* Dialog Actions */
.edit-dialog-actions {
  @apply !flex !justify-start !space-x-4 !mx-3 ; /* Left-aligned buttons with spacing */
}

/* Buttons */
.edit-ok-button, .edit-cancel-button {
  @apply !rounded-2xl !py-2 !px-6 !text-black !font-bold; /* Rounded buttons with padding */
}

/* OK Button */
.edit-ok-button {
  @apply bg-gradient-to-b from-[#dedede] to-[#a1a1a1]; /* Gradient background for OK button */
}

/* Cancel Button */
.edit-cancel-button {
  @apply bg-gradient-to-b from-[#dedede] to-[#a1a1a1]; /* Light gray background for Cancel button */
}

.invalid-details-text{
  @apply font-bold text-red-600;
}

.dark-mode .invalid-details-text{
  @apply text-slate-400;
}

.dark-mode .add-company-input input{
  @apply !placeholder-white !text-white;
}

.dark-mode .add-company-input svg{
  @apply text-white;
}

