@tailwind base;
@tailwind components;
@tailwind utilities;

/* .table-container {
  @apply p-4;
}

.back-button {
  @apply bg-gray-300 hover:bg-gray-400;
}

.sort-button {
  @apply bg-green-300 hover:bg-green-400;
}

.delete-button {
  @apply bg-red-300 hover:bg-red-400;
}

.retrieve-button {
  @apply bg-blue-300 hover:bg-blue-400;
} 

.dark-mode .table-cell svg{
  @apply text-white;
}

.dark-mode .table-cell-actions svg{
  @apply text-white;
}

.dark-mode .nested-table-cell svg{
  @apply text-white;
}

.dark-mode .nested-table-cell-actions svg{
  @apply text-white;
}

.data-table {
  @apply w-full rounded-lg;
}

.dark-mode .data-table{
  @apply bg-zinc-700;
}

.col1{
  @apply w-10;
}

.nested-table-container {
  @apply mt-0;
}

.contact-button, .email-button, .linkedin-link {
  @apply font-bold text-blue-700 no-underline hover:underline;
}

.dark-mode .contact-button, .dark-mode .email-button, .dark-mode .linkedin-link {
  @apply text-slate-200;
}

.empty-row {
  @apply h-16;
}

.dark-mode .custom-pagination {
  @apply bg-zinc-800;
} */
