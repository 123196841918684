  .pagination-container {
    @apply flex justify-between items-center p-4 rounded-b-lg;
  }

  .dark-mode .pagination-container {
    @apply bg-[#1c2633];
  }
  
  .rows-per-page-container {
    @apply flex items-center gap-2;
  }

  .dark-mode .rows-per-page-container{
    @apply text-white;
  }
  
  .rows-per-page-select {
    @apply border border-gray-300 rounded px-2 py-1 text-sm text-black;
  }
  
  .page-numbers-container {
    @apply flex justify-center flex-grow;
  }

  .dark-mode .page-numbers-container{
    @apply text-white;
  }
  
  .page-number {
    @apply px-3 py-2 mx-1 bg-white text-gray-800 border border-gray-300 rounded cursor-pointer text-sm;
  }
  
  .page-number:hover {
    @apply bg-gray-200;
  }
  
  .page-number.active {
    @apply bg-gray-300 font-semibold;
  }
  
  .nav-buttons-container {
    @apply flex gap-2;
  }
  
  .nav-button {
    @apply bg-white text-gray-800 px-4 py-2 border border-gray-300 rounded flex items-center cursor-pointer shadow-sm text-sm;
  }
  
  .nav-button:hover {
    @apply bg-gray-200;
  }
  
  .nav-button:disabled {
    @apply cursor-not-allowed opacity-60;
  }
  
  .icon {
    @apply mr-2;
  }
  
  .jump-to-page-container {
    @apply flex items-center gap-2 ml-2;
  }
  
  .jump-to-page-input {
    @apply border border-gray-300 rounded py-2 px-1 text-sm w-14 text-center;
  }
  
  .jump-to-page-button {
    @apply bg-blue-500 text-white px-3 py-2 rounded cursor-pointer text-sm;
  }
  
  .jump-to-page-button:hover {
    @apply bg-blue-600;
  }
  