/* MobileNavbar.css */
.mobile-navbar {
    @apply w-full align-middle bg-white shadow-md;
}

.dark-mode .mobile-navbar{
    @apply bg-[#1c2633];
}

.mobile-navbar-container {
    @apply flex justify-around items-center px-2 border-t mb-2;
}

.mobile-navbar-item {
    @apply rounded-full transition-colors duration-300 ease-in-out bg-gray-100 hover:bg-gray-200 mt-2;
}

.mobile-navbar-link {
    @apply rounded-full p-2 flex items-center justify-center text-black;
}

.active.mobile-navbar-link {
    @apply bg-[#1E54AC] text-white;
}

.mobile-navbar-icon {
    @apply w-6 h-6;
}

/* Active state for the selected icon */
.mobile-navbar-item.active {
    @apply bg-blue-100 text-[#1E54AC];
}
