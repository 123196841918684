@tailwind base;
@tailwind components;
@tailwind utilities;

.app-table-container {
  @apply p-0;
}

.app-loading-container {
  @apply flex justify-center items-center h-full mt-16;
}

.app-paper-container {
  @apply p-0 border-none outline-none;
  margin-bottom: 56px;
}

.dark-mode .app-paper-container{
  @apply !border-white;
}

.dark-mode .app-table-cell svg{
  @apply text-[#cbd5e1] bg-[#1c2633];
}

.dark-mode .app-nested-table-cell svg{
  @apply text-[#cbd5e1];
}

.dark-mode .app-name-table-cell svg{
  @apply text-[#cbd5e1];
}

.app-data-table {
  @apply w-full border-y !border-collapse;
}

.dark-mode .app-data-table{
  @apply bg-[#1c2633];
}

.app-table-head {
  @apply bg-gray-100 !font-bold py-1;
}

.dark-mode .app-table-head .app-table-row {
  @apply !bg-[#1c2633] text-[#ededed] hover:!bg-[#2c2633];
}

.app-table-row {
  @apply hover:bg-gray-50;
}

.dark-mode .app-table-row {
  @apply bg-[#19222c] hover:!bg-[#29222c] text-[#ededed];
}

.app-table-head .app-table-row .app-table-cell{
  @apply !border-t-0;
}

.app-table-cell {
  @apply py-2 text-left !border-y !border-black !font-bold;
}

.dark-mode .app-table-cell {
  @apply !border-white text-[#ededed];
}

.app-name-table-container{
    @apply mt-0;
}

.app-nested-table-container {
  @apply mt-0;
}

.app-nested-table {
  @apply w-full !border-collapse;
}

.app-name-table {
  @apply w-full !border-collapse;
}

.app-nested-table-head {
  @apply bg-gray-100 font-bold !border-y !border-black;
}

.dark-mode .app-nested-table-head .app-nested-table-row{
  @apply !bg-[#1c2633] text-[#ededed] !border-white hover:!bg-[#2c2633];
}

.app-nested-table-row {
  @apply bg-[#f4f2ff] !border-y !border-black !h-14;
}

.dark-mode .app-nested-table-row {
  @apply bg-[#19222c] hover:!bg-[#29222c] text-[#ededed];
}

.app-name-table-row {
  @apply h-12 bg-[#f4f2ff] !border-b !border-black;
}

.dark-mode .app-name-table-row {
  @apply bg-[#19222c] hover:!bg-[#29222c] text-[#ededed];
}

.app-nested-table-cell-container{
  @apply flex justify-center items-center;
}

.app-nested-table-cell {
  @apply px-2 py-2 flex flex-col items-center !font-bold  w-1/4 min-w-0;
}

.app-name-table-cell {
  @apply px-2 py-2 text-left !font-bold w-1/12;
}

.dark-mode .app-nested-table-cell{
  @apply !border-white text-[#ededed];
}

.dark-mode .app-name-table-cell{
  @apply !border-white text-[#ededed];
}

.app-contact-button, .app-email-button, .app-linkedin-link, .app-more-info-button {
  @apply font-bold !text-[#0d6efd] no-underline hover:underline flex flex-col items-center;
}

.dark-mode .app-contact-button svg, .dark-mode .app-email-button svg, .dark-mode .app-linkedin-link svg, .dark-mode .app-more-info-button svg{
  @apply font-bold !text-[#0d6efd] no-underline hover:underline;
}

.app-edit-dialog-title {
  @apply flex items-center text-xl font-bold text-gray-700; /* Centered title with icon */
}

.dark-mode .app-edit-dialog-title{
  @apply text-[#ededed];
}

/* Divider Line */
.app-edit-dialog-divider {
  @apply border-y-2 border-black my-2; /* Light gray horizontal line */
}

.dark-mode .app-edit-dialog-divider{
  @apply border-slate-400;
}

/* Dialog Content */
.app-edit-dialog-content {
  @apply space-y-4; /* Spacing between input fields */
}

/* Label and Input Group */
.app-edit-label-input-group {
  @apply flex flex-wrap items-center space-y-1; /* Flex column for labels and inputs */
}

/* Labels */
.app-edit-label {
  @apply font-extrabold text-gray-800 px-0 py-2 w-[100px] mr-2; /* Darker gray label text */
}

.dark-mode .app-edit-label {
  @apply !text-slate-100;
}

.dark-mode .app-inside-dialog{
  @apply bg-zinc-800;
}

/* Input Fields */
.app-edit-input {
  @apply bg-slate-100 rounded-md !placeholder-gray-400 border border-gray-700 px-2 py-1 w-[calc(100%-160px)]; /* White background, rounded corners, gray placeholder, border */
}

.dark-mode .app-edit-input {
  @apply !bg-zinc-600 !text-[#ededed];
}

/* .branch-options {
  @apply flex space-x-2 mt-2; 
}
.branch-chip {
  @apply bg-gray-200 text-gray-700 px-3 py-1 rounded-full cursor-pointer hover:bg-gray-300; 
} */

/* Dialog Actions */
.app-edit-dialog-actions {
  @apply !flex !flex-wrap !justify-start !mx-2; /* Left-aligned buttons with spacing */
}

/* Buttons */
.app-edit-ok-button, .app-edit-cancel-button {
  @apply !my-1 !mx-0 !rounded-2xl !py-2 !px-6 !text-white !font-bold !w-full !bg-[#1E54AC]; /* Rounded buttons with padding */
}

/* .dark-mode .MuiSvgIcon-root {
  color: #ddd;
} */

.app-invalid-details-text{
  @apply font-bold text-red-600;
}

.dark-mode .app-invalid-details-text{
  @apply text-slate-400;
}

.dark-mode .app-edit-lastcontacted-input input{
  @apply !placeholder-white !text-[#ededed];
}

.dark-mode .app-edit-lastcontacted-input svg{
  @apply text-[#ededed];
}

.app-contact-dialog-text{
  @apply flex flex-wrap py-1;
}

.dark-mode .app-contact-dialog-text{
  @apply text-[#ededed];
}

.app-contact-dialog-divider {
  @apply border-y-2 border-black my-1; /* Light gray horizontal line */
}

.dark-mode .app-contact-dialog-divider{
  @apply border-slate-400;
}

.app-status-select{
  @apply text-center w-60;
}

.dark-mode .app-status-select{
  @apply text-[#ededed] border border-white;
}

.dark-mode .app-status-select-item{
  @apply text-[#ededed] bg-zinc-700 hover:!bg-zinc-600;
}

.dark-mode .Mui-selected.status-select-item{
  @apply !bg-zinc-600;
}

.dark-mode .status-select svg{
  @apply text-[#ededed];
}

.app-autocomplete-group{
  @apply sticky font-bold -top-2 px-3 py-3 z-10 bg-slate-200;
}

.app-autocomplete-item{
  @apply p-0;
}

.dark-mode .app-autocomplete-group{
  @apply text-[#ededed] bg-gray-900 hover:bg-gray-950;
}

.dark-mode .app-autocomplete-item{
  @apply text-[#ededed] bg-gray-700;
}

.dark-mode .app-autocomplete-input textarea{
  @apply !text-[#ededed];
}

.dark-mode .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPopover-paper.MuiMenu-paper{
  @apply border-2 border-white bg-zinc-700;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPopover-paper.MuiMenu-paper{
  @apply border-2 border-black shadow-none;
}

.app-search-filter-dialog{
  @apply fixed w-full;
  z-index: 500;
}


