.footer {
    @apply bg-[#E9F3FD] py-6 text-gray-700 w-full;
}

.footer-container {
    @apply container px-4;
}

.footer-icons {
    @apply flex space-x-4 text-black text-3xl;
}

.footer-icon {
    @apply text-blue-700;
}

.whatsapp-icon {
    color: #25D366;
}

.footer-contact {
    @apply sm:items-center text-left sm:text-left w-full sm:w-auto;
}

.footer-contact h2 {
    @apply text-2xl font-bold;
}

.footer-contact-info {
    @apply flex justify-between items-center space-x-4 mt-2;
}

.contact-item {
    @apply flex items-center space-x-2;
}

.footer-link {
    @apply text-lg font-bold;
}

.footer-divider {
    @apply border-t-2 border-black my-4;
}

.footer-developed {
    @apply text-lg font-bold text-center py-2;
}

@media (max-width: 600px){
    .footer-developed{
        @apply mb-16;
    }
}

.footer-heart {
    @apply text-red-500;
}

.footer-link {
    @apply text-[#007bff] no-underline;
}

.footer-link:hover {
    @apply underline;
}

.clickable {
    @apply text-[#007bff] underline cursor-pointer;
}

/* Dialog Custom Styling */
.dialog-container {
    @apply bg-[#E9F3FD] dark:bg-gray-800 shadow-xl rounded-3xl p-6 !important; /* Increased rounding with rounded-3xl */
}

.dialog-title {
    @apply text-5xl font-semibold text-center mb-4 dark:text-white  !important;
}

.dialog-content {
    @apply space-y-4 !important;
}

.dialog-actions {
    @apply flex justify-end mt-6 !important;
}

.dialog-button {
    @apply bg-blue-500 dark:bg-blue-400 text-white py-2 px-4 rounded-full hover:bg-blue-700 transition-all !important;
}

.footer {
    @apply bg-[#E9F3FD] py-6 text-gray-700 w-full;
}

.footer-container {
    @apply container px-4;
}

.footer-icons {
    @apply flex space-x-4 text-black text-3xl;
}

.footer-icon {
    @apply text-blue-700;
}

.whatsapp-icon {
    color: #25D366;
}

.footer-contact {
    @apply sm:items-center text-left sm:text-left w-full sm:w-auto;
}

.footer-contact h2 {
    @apply text-2xl font-bold;
}

.contact-item {
    @apply flex items-center space-x-2;
}

.footer-link {
    @apply text-lg font-bold;
}

.footer-divider {
    @apply border-t-2 border-black my-4;
}

.footer-developed {
    @apply text-lg font-bold text-center py-2;
}

.footer-heart {
    @apply text-red-500;
}

.footer-link {
    @apply text-[#007bff] no-underline;
}

.footer-link:hover {
    @apply underline;
}

.clickable {
    @apply text-[#007bff] underline cursor-pointer;
}

/* Dialog Custom Styling */
.dialog-container {
    @apply bg-[#E9F3FD] shadow-xl rounded-3xl p-6 !important;
}

.dialog-title {
    @apply text-5xl font-semibold text-center mb-4 text-black !important;
}

.dialog-content {
    @apply space-y-4 !important;
}

.dialog-actions {
    @apply flex justify-end mt-6 !important;
}

.dialog-button {
    @apply bg-blue-500 text-white py-2 px-4 rounded-full hover:bg-blue-700 transition-all !important;
}

.dialog-link {
    @apply text-black font-extrabold text-2xl hover:text-gray-800 underline !important;
}

.dialog-role {
    @apply text-black text-lg !important;
}

/* Dark Mode Specific Styling */
.dark-mode .dialog-container {
    @apply bg-gray-800 !important;
}

.dark-mode .dialog-title, 
.dark-mode .dialog-role {
    @apply text-white !important;
}

.dark-mode .dialog-link {
    @apply text-white !important;
}

.dark-mode .dialog-button {
    @apply bg-blue-400 hover:bg-blue-600 !important;
}

.footer-sign{
    @apply text-xl;
}

@media(max-width: 700px){
    .footer-sign{
        @apply text-base;
    }
}
