@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-[#E9F3FD];
}

.sign-in-wrapper {
  @apply flex flex-col min-h-screen  items-center justify-center;
}

.sign-in-container {
  @apply w-[90%] max-w-[500px] p-6 border border-gray-300 rounded-lg bg-white shadow-lg mx-auto my-auto;
}

.welcome-text {
  @apply text-3xl font-bold text-black mb-2;
  text-align: left;
}

.subtext {
  @apply text-gray-600 mb-6;
  text-align: left;
}

.sign-in-form {
  @apply flex flex-col;
}

.form-group {
  @apply mb-4;
}

.form-input {
  @apply w-full p-3 text-lg border border-gray-300 rounded-lg bg-white focus:outline-none focus:border-blue-500;
}

.submit-button {
  @apply p-3 text-lg cursor-pointer rounded-full transition-colors duration-300 ease-in-out;
  background-color:  #007AFF;
  color: white;
  border: none;
}

.submit-button:hover {
  background-color: #7acfee;
}

.error-message {
  @apply text-red-500 mt-2 text-sm;
}

.footer {
  @apply text-center py-4 text-gray-500 w-full mt-auto;
  background-color: #C7CFD6;
}