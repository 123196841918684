.mobile-pagination-container {
    @apply flex justify-around items-center p-4 rounded-b-lg;
  }

  .dark-mode .mobile-pagination-container {
    @apply bg-[#1c2633];
  }
  
  .mobile-rows-per-page-container {
    @apply flex items-center gap-2;
  }

  .dark-mode .mobile-rows-per-page-container{
    @apply text-white;
  }
  
  .mobile-rows-per-page-select {
    @apply border border-gray-300 rounded p-0 mr-1 text-sm text-black;
  }
  
  .mobile-page-numbers-container {
    @apply flex justify-center;
  }

  .dark-mode .mobile-page-numbers-container{
    @apply text-white;
  }
  
  .mobile-page-number {
    @apply py-1 px-1 mx-1 border bg-white text-gray-800 rounded cursor-pointer text-sm;
  }
  
  .mobile-page-number:hover {
    @apply bg-gray-200;
  }
  
  .mobile-page-number.mobile-active {
    @apply bg-gray-300 font-semibold;
  }
  
  .mobile-nav-buttons-container {
    @apply flex gap-2;
  }
  
  .mobile-nav-button {
    @apply bg-white text-center text-gray-800 m-0 px-0 py-1 rounded flex justify-center items-center cursor-pointer text-sm;
  }
  
  .mobile-nav-button:hover {
    @apply bg-gray-200;
  }
  
  .mobile-nav-button:disabled {
    @apply cursor-not-allowed opacity-60;
  }
  
  .mobile-icon {
    @apply p-0;
  }
  
  .mobile-jump-to-page-container {
    @apply flex items-center gap-2 ml-2;
  }
  
  .mobile-jump-to-page-input {
    @apply border border-gray-300 rounded py-2 px-1 text-sm w-14 text-center;
  }
  
  .mobile-jump-to-page-button {
    @apply bg-blue-500 text-white px-3 py-2 rounded cursor-pointer text-sm;
  }
  
  .mobile-jump-to-page-button:hover {
    @apply bg-blue-600;
  }
  