/* Search container styling */
.search-filter-container {
    @apply sticky bottom-0 flex justify-between items-center w-full p-2 bg-[#ECE6F0] z-10;
}

.dark-mode .search-filter-container {
    @apply bg-zinc-700;
}

.search-filter-container.focused {
    @apply bottom-0 left-0;
    z-index: 2000;
}
  
/* Filter icon styling */
.filter-icon {
    @apply outline-none cursor-pointer ml-4 mr-2;
}
  
/* Search input styling */
.search-input {
    @apply flex p-2 w-full bg-transparent outline-none;
}

.search-icon {
    @apply cursor-pointer ml-2 mr-3 font-bold;
}

/* Filter section styling */
.filter-section {
    @apply p-3 bg-[#ECE6F0];
    border: 1px solid #ddd;  /* Add border similar to the design */
}

.dark-mode .filter-section {
    @apply bg-zinc-700;
}

/* Align radio buttons vertically */
.filter-section h3 {
    @apply mb-4;
}

.filter-section div {
    @apply mb-3 flex items-center;
}

.filter-section label {
    @apply ml-2;
}

/* Filter buttons styling */
.filter-buttons {
    @apply flex justify-around mt-5;
}

.clear-filters {
    @apply bg-white text-black px-4 py-2 border border-[#ccc] rounded-lg shadow-sm;
}

.apply-filters {
    @apply bg-[#1E54AC] text-white px-8 py-2 rounded-lg shadow-sm;
}
