/* Custom Tailwind Classes for the Contact component */

.contact-container {
    @apply bg-white rounded-xl p-8 text-center mx-6 mt-8 text-center;
  }

  .dark-mode .contact-container {
    @apply bg-zinc-700;
  }
  
  .contact-heading {
    @apply text-2xl font-bold mb-6;
  }
  
  .contact-info {
    @apply space-y-4;
  }
  
  .contact-item {
    @apply flex items-center justify-center space-x-2 text-lg;
  }
  
  .contact-icon {
    @apply text-[#0d6efd];
  }
  
  .contact-text {
    @apply font-medium max-w-[250px];
  }
  
  .contact-socials {
    @apply flex justify-evenly mt-4;
  }
  
  .contact-social-icon {
    @apply w-8 h-8 text-[#0d6efd] cursor-pointer;
  }
  
  .contact-footer {
    @apply mt-8 text-sm text-gray-600;
  }
  
  .footer-link {
    @apply text-[#0d6efd] font-bold cursor-pointer;
  }
  
.mobile-linkedin-icon {
    @apply text-[#0076B2];
}

.dark-mode .mobile-linkedin-icon {
  @apply text-white;
}

.mobile-footer-developed {
    @apply bg-[#1687D9] rounded-xl px-2 py-2 mx-6 mt-4 text-white text-center;
  }
  
  .mobile-footer-heart {
    @apply text-red-500; /* Red heart icon */
  }
  
  .mobile-footer-link {
    @apply text-white; /* Make link text bold */
  }
  
  .mobile-footer-divider {
    @apply ml-2 mr-2;
  }
  
